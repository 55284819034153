/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from 'components/layout/Layout'
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import Levenshtein from 'js-levenshtein'
import Plaatjie from '@ubo/plaatjie'

const Section = styled.section``

const NotFound = styled.div`
  & h1 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.secondary};
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & p {
    & b,
    strong {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  & a {
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 992px) {
    & h1 {
      font-size: 100px;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border-bottom-right-radius: 50px;
  padding: 20px 20px 20px 20px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  & a {
    color: ${({ theme }) => theme.color.secondary};

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const Links = styled.div`
  & p {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const BackToHome = styled.div`
  & a {
    text-decoration: underline;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.color.light};

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const Or = styled.div`
  padding: 10px 0;
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

interface Props {
  location: any
}

const WhiteBlock = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 50px;
  padding: 10px 30px;
  background-color: ${({ theme }) => theme.color.light};
`

const ContentWrapper = styled.div`
  z-index: 7;
`

const Gradient = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg, #012345 25.92%, rgba(1, 35, 69, 0) 80%);
  mix-blend-mode: multiply;
  opacity: 0.6;
  z-index: 6;
`

const NotFoundPage: React.FC<Props> = ({ location }) => {
  // @ts-ignore
  const query = useStaticQuery<GatsbyTypes.notfoundQuery>(graphql`
    query notfound {
      allWpPage {
        nodes {
          title
          uri
        }
      }

      fields: wpComponent(databaseId: { eq: 686 }) {
        notFound {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 1440)
              }
            }
          }
        }
      }
    }
  `)

  const [list, setList] = React.useState([])

  React.useEffect(() => {
    const levenshteinList: any = []

    query.allWpPage.nodes.forEach((n: any) => {
      levenshteinList.push({
        uri: n.uri,
        title: n.title,
        score: Levenshtein(location.pathname, n.uri),
      })
    })

    setList(levenshteinList)
  }, [])

  const fields = query.fields?.notFound

  return (
    <Layout>
      <Section className="position-relative">
        <Plaatjie
          image={fields?.image}
          alt=""
          className="position-absolute mx-auto h-100 w-100"
        />
        <Gradient className="position-absolute" />
        <ContentWrapper className="position-relative container py-lg-5 py-3">
          <NotFound className="row justify-content-center mt-5 pt-5">
            <div className="col-lg-8 pb-lg-5 pb-3">
              <WhiteBlock>
                <h1>Oops!</h1>
                <h3>Looks like this page doesn't exist.</h3>
                <div className="py-3">
                  <p className="mb-0">
                    Error code: <b>404</b>
                  </p>
                </div>
              </WhiteBlock>
            </div>
            <Links className="col-lg-9">
              <BackToHome>
                <NavLink to="/">Go back to the homepage</NavLink>
              </BackToHome>
              <Or>OR</Or>
              <p className="mb-0 text-white">
                see some pages that may be the one you're looking for.
              </p>
              <Block>
                {list
                  .sort((a: any, b: any) => a.score - b.score)
                  .map((n: any, index: number) => (
                    <div key={index}>
                      <NavLink to={n.uri}>{n.title}</NavLink>
                    </div>
                  ))}
              </Block>
            </Links>
          </NotFound>
        </ContentWrapper>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
